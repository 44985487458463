import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"
import "./src/styles/foundation/testimonials.css"


export const onInitialClientRender = () => {
  window.__GATSBY_INITIAL_RENDER_COMPLETE__ = false;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    window.__GATSBY_INITIAL_RENDER_COMPLETE__ = true;
  }
};